body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none; /*禁用手机浏览器的用户选择功能 */
  -moz-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tip {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -140px;
  width: 280px;
  height: 60px;
  border-radius: 10px;
  background-color: rgba(190, 190, 190, 0.15);
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0;
  padding: 6px;
  z-index: 999;
  border-radius: 10px;

  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  display: flex;
  justify-content: center;
  align-items: center;
}

.tip-open {
  top: 60%;
  opacity: 1;
}

input {
  border: 0;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.competence-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.competence-page img {
  width: 50px;
  margin-top: 100px;
}

.left-img {
  width: 20px;
  height: 20px;
}


.tip {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -140px;
  width: 280px;
  height: 60px;
  border-radius: 10px;
  background-color: rgba(190, 190, 190, 0.15);
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0;
  padding: 6px;
  z-index: 999;
  border-radius: 10px;

  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  display: flex;
  justify-content: center;
  align-items: center;
}

.tip-open {
  top: 60%;
  opacity: 1;
}
